import { Box, Container, Heading, VStack, Text } from "@chakra-ui/layout";
import { Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Post as IPost } from "../interfaces/post";
import { config } from "../config";
import { Post } from "./Post";

const Posts = () => {
  const [posts, setPosts] = useState<IPost[]>([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(`${config.workersUrl}/posts`);
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (
    <>
      <Container mt={5}>
        <Text fontWeight="bold" mb={1}>
          Posts
        </Text>
        <Box
          bg="white"
          padding={2}
          borderRadius={15}
          borderColor="#ccc"
          borderWidth={1}
        >
          <VStack>
            {posts?.map((post: IPost, index: number) => (
              <>
                <Post post={post} />

                {/* Display a divider after all except the last post */}
                {index < posts.length - 1 && <Divider />}
              </>
            ))}
          </VStack>
        </Box>
      </Container>
    </>
  );
};

export default Posts;
