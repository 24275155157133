import { Avatar } from "@chakra-ui/avatar";
import { HStack, Text } from "@chakra-ui/layout";
import MDEditor from "@uiw/react-md-editor";
import { Link } from "react-router-dom";
import { Post as IPost } from "../interfaces/post";

interface PostProps {
  post: IPost;
}

const Post = ({ post }: PostProps) => {
  return (
    <div key={post.id}>
      <HStack>
        <Avatar name={post.username} size="sm" />
        <Text> {post.username} </Text>
      </HStack>
      <h2>
        <Link to={`/posts/${post.id}`}>{post.title}</Link>
      </h2>
      <MDEditor.Markdown source={post.content} />
    </div>
  );
};

export { Post };
