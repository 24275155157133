import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
// import Posts from "./components/Posts";
import MainLayout from "./components/MainLayout";
import Posts from "./components/Posts";

const colors = {
  // use Cloudlfare's brand colors
  brand: {
    main: "#f63",
    light: "#fbad41",
    900: "#f63",
    800: "#fbad41",
  },
};

const theme = extendTheme({
  colors,
});

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<Posts />} />
          </Route>
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
