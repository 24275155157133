import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input } from "@chakra-ui/input";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { Text } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import { config } from "../config";
import React, { useEffect } from "react";
import BrandButton from "./BrandButton";
import MDEditor from "@uiw/react-md-editor";

const CreatePost = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submitting, setSubmitting] = React.useState(false);

  const [username, setUsername] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState(
    "**Write content about your post in Markdown! This editor fully supports markdown as well as common keyboard shortcuts. Feel free to include images as well :) **"
  );

  const handleSubmit = async () => {
    setSubmitting(true);
    const data = new FormData();
    data.append("username", username);
    data.append("title", title);
    data.append("content", content);

    await fetch(`${config.workersUrl}/posts`, { body: data, method: "post" });
    onClose();
    setSubmitting(false);
  };

  return (
    <>
      <BrandButton onClick={onOpen} size="sm">
        Create a Post
      </BrandButton>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create your Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                placeholder="Username"
                onChange={(event) => setUsername(event.currentTarget.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Post Title</FormLabel>
              <Input
                placeholder="Title"
                onChange={(event) => setTitle(event.currentTarget.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Post Content</FormLabel>

              <MDEditor
                value={content}
                preview="edit"
                onChange={(e) => {
                  if (e) setContent(e);
                  else setContent("");
                }}
              />
              <Text mt={2}> Live Preview </Text>
              <MDEditor.Markdown source={content} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <BrandButton ml={3} onClick={handleSubmit} isLoading={submitting}>
              Submit Post
            </BrandButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreatePost;
