import { Box } from "@chakra-ui/layout";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";

const MainLayout = () => {
  return (
    <Box minHeight="100vh" bg="#dae0e6">
      <NavBar />
      <Outlet />
    </Box>
  );
};

export default MainLayout;
