import { Button, ButtonProps } from "@chakra-ui/button";

const BrandButton = (props: ButtonProps) => {
  return (
    <Button
      color="white"
      backgroundColor="brand.main"
      _hover={{ backgroundColor: "brand.light" }}
      {...props}
    />
  );
};

export default BrandButton;
