import { Box, HStack, Text, Stack, Link, Button, Flex } from "@chakra-ui/react";
import CreatePost from "./CreatePost";

const NavBar = () => {
  return (
    <Stack
      direction={"row"}
      spacing={4}
      bg="white"
      height={10}
      align="center"
      paddingX={4}
    >
      <Box>
        <HStack spacing="0">
          <Text color="brand.main" fontWeight="800">
            Flare
          </Text>
          <Text color="brand.light" fontWeight="600">
            Media
          </Text>
        </HStack>
      </Box>

      <Box>
        <Link
          p={2}
          href={"#"}
          fontSize={"sm"}
          fontWeight={500}
          color="gray.600"
          _hover={{
            textDecoration: "none",
            color: "gray.800",
          }}
        >
          PlaceHolder Link
        </Link>
      </Box>
      <Flex flex={{ base: 1 }} />

      <CreatePost />
    </Stack>
  );
};

export default NavBar;
